import './App.css';
import "./fonts/ApfelGrotezk-Fett.otf";
import "./fonts/Blambot-Pro-Regular.ttf";
import "./fonts/Coconat-Regular.otf";
import Hero from './components/hero/hero';
import Content from './components/content/content';
import Footer from './components/footer/footer';

function App() {
  return (
    <>
      <Hero />
      <Content />
      <Footer />
    </>
  );
}

export default App;

import "./footer.css";

export default function Footer() {
    return(
        <>
            <div className="footer_main">
                <h2>FOLLOW OUR JOURNEY</h2>
                <div className='soc_icons'>
                    <a className="fb_svg" href="https://www.facebook.com/profile.php?id=61557952833027" target='_blank'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M17.7795 6.44008H20.4795C20.7776 6.44008 21.0195 6.19816 21.0195 5.90008V2.2621C21.0195 1.97914 20.8019 1.7437 20.52 1.72372C19.6609 1.6627 17.9825 1.58008 16.7756 1.58008C13.4595 1.58008 11.2995 3.56728 11.2995 7.1788V10.7601H7.51949C7.22141 10.7601 6.97949 11.002 6.97949 11.3001V15.0801C6.97949 15.3782 7.22141 15.6201 7.51949 15.6201H11.2995V25.8801C11.2995 26.1782 11.5414 26.4201 11.8395 26.4201H15.6195C15.9176 26.4201 16.1595 26.1782 16.1595 25.8801V15.6201H20.0594C20.3348 15.6201 20.5659 15.4133 20.5961 15.1395L21.0163 11.3595C21.0519 11.0398 20.8013 10.7601 20.4795 10.7601H16.1595V8.06008C16.1595 7.1653 16.8847 6.44008 17.7795 6.44008Z" fill="#EAEBDF"/>
                            </svg>
                        </span>
                    </a>
                    <a className="insta_svg" href="https://www.instagram.com/easypeasy.studio" target='_blank'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M9.14012 2.12012C5.26832 2.12012 2.12012 5.26832 2.12012 9.14012V18.8601C2.12012 22.7319 5.26832 25.8801 9.14012 25.8801H18.8601C22.7319 25.8801 25.8801 22.7319 25.8801 18.8601V9.14012C25.8801 5.26832 22.7319 2.12012 18.8601 2.12012H9.14012ZM20.4801 6.44012C21.0741 6.44012 21.5601 6.92612 21.5601 7.52012C21.5601 8.11412 21.0741 8.60012 20.4801 8.60012C19.8861 8.60012 19.4001 8.11412 19.4001 7.52012C19.4001 6.92612 19.8861 6.44012 20.4801 6.44012ZM14.0001 8.06012C17.2779 8.06012 19.9401 10.7223 19.9401 14.0001C19.9401 17.2779 17.2779 19.9401 14.0001 19.9401C10.7223 19.9401 8.06012 17.2779 8.06012 14.0001C8.06012 10.7223 10.7223 8.06012 14.0001 8.06012ZM14.0001 9.14012C11.3217 9.14012 9.14012 11.3217 9.14012 14.0001C9.14012 16.6785 11.3217 18.8601 14.0001 18.8601C16.6785 18.8601 18.8601 16.6785 18.8601 14.0001C18.8601 11.3217 16.6785 9.14012 14.0001 9.14012Z" fill="#EAEBDF"/>
                            </svg>
                        </span>
                    </a>
                    <a className="linkedin_svg" href="https://www.linkedin.com/company/easy-peasy-creative-studio/" target='_blank'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M26.25 26.375H21.1646V17.7135C21.1646 15.3388 20.2623 14.0117 18.3827 14.0117C16.3379 14.0117 15.2696 15.3927 15.2696 17.7135V26.375H10.3688V9.875H15.2696V12.0975C15.2696 12.0975 16.7432 9.3709 20.2446 9.3709C23.7444 9.3709 26.25 11.5081 26.25 15.9282V26.375ZM4.52205 7.71446C2.85271 7.71446 1.5 6.35113 1.5 4.66973C1.5 2.98832 2.85271 1.625 4.52205 1.625C6.19138 1.625 7.54329 2.98832 7.54329 4.66973C7.54329 6.35113 6.19138 7.71446 4.52205 7.71446ZM1.99145 26.375H7.10178V9.875H1.99145V26.375Z" fill="#EAEBDF"/>
                            </svg>
                        </span>
                    </a>
                    <a className="tiktok_svg" href="https://www.tiktok.com/@easy.peasy.studio" target='_blank'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M22.6402 2.66016H5.36016C3.87138 2.66016 2.66016 3.87138 2.66016 5.36016V22.6402C2.66016 24.1289 3.87138 25.3402 5.36016 25.3402H22.6402C24.1289 25.3402 25.3402 24.1289 25.3402 22.6402V5.36016C25.3402 3.87138 24.1289 2.66016 22.6402 2.66016ZM20.4834 12.5546C20.3608 12.5659 20.2366 12.5735 20.1108 12.5735C18.6944 12.5735 17.4497 11.845 16.7255 10.744C16.7255 13.6324 16.7255 16.9189 16.7255 16.9739C16.7255 19.5168 14.6638 21.5785 12.121 21.5785C9.5781 21.5785 7.51638 19.5168 7.51638 16.9739C7.51638 14.4311 9.5781 12.3694 12.121 12.3694C12.2171 12.3694 12.311 12.378 12.4055 12.3839V14.653C12.311 14.6417 12.2182 14.6244 12.121 14.6244C10.8228 14.6244 9.77088 15.6763 9.77088 16.9745C9.77088 18.2726 10.8228 19.3246 12.121 19.3246C13.4191 19.3246 14.5655 18.3018 14.5655 17.0036C14.5655 16.9523 14.5882 6.42288 14.5882 6.42288H16.7569C16.961 8.36202 18.5264 9.89238 20.4834 10.0328V12.5546Z" fill="#EAEBDF"/>
                            </svg>
                        </span>
                    </a>
                </div>
                <div className="call_us_main">
                    <div className="call_us_sub">
                        <img src="/images/call_me.png" alt="cal_me" />
                        <a className="call_us_link" href="https://calendly.com/easypeasystudio/30min" target="_blank">
                            <p>SCHEDULE A CALL</p>
                        </a>
                    </div>
                </div>
                <div className="credentials">
                    <span className="mail_to">
                        <a href="mailto:hello@easypeasy.studio" target='_blank'>hello@easypeasy.studio</a>
                    </span>
                    <p>© 2024 EASYPEASY.STUDIO</p>
                </div>
            </div>
        </>
    );
}
import React, { useState } from 'react';
import "./content.css";

export default function Content() {
    const [hover, setHover] = useState(false);
    const original = {
        opacity: hover ? '0' : '1',
        transition: "opacity 0.5s ease"
    };
    const inverted = {
        opacity: hover ? '1' : '0',
        transition: "opacity 0.5s ease"
    };

    return(
        <>
            <div className="content_main">
                <section className="content_top">
                    <h2 className="content_title">Stand Out!</h2>
                    <h4>Get Eaten!</h4>
                    <div className='stroke_container'>
                        <img className="stroke stroke_original" src="/images/stroke_green.png" alt="stroke"/>
                    </div>
                    <p>... and Discover <br />the Delicious Part of Success.</p>
                </section>
                <div className="button_main">
                    <div className="button_sub"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <div className='button_image'>
                            <img src="/images/button_border_original.png" alt="border"/>
                        </div>
                        <a className="button_link" href="/EasyPeasy_StandOut.pdf" target="_blank">
                            <p>ACCEPT THE CHALLENGE!</p>
                        </a>
                    </div>
                    <div className="button_stars">
                        <img src="/images/stars_left.png" alt="stars" style={inverted}/>
                        <img src="/images/stars_right.png" alt="stars" style={inverted}/>
                    </div>
                </div>
                <div className="disclaimer">
                    <p>
                        <span>WARNING!</span> ACCEPTING THE CHALLENGE TO STAND OUT MIGHT TURN YOUR COMPETITORS GREEN WITH ENVY
                    </p>
                </div>
                <div className="section_end">
                    <img className='section_end_black' src="/images/section_end_black.png" alt="section_end"/>
                </div>
            </div>
        </>
    );
}

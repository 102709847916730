import "./hero.css";

export default function Hero() {
    return(
        <div className="hero_main">
            <img className="cover_image" src="/images/hero_bg.png" alt="cover" />
            <div className="hero_text">
                <h3>EFFECTIVE COMMUNICATION <br /> & BRAND JOURNEY <br /> STUDIO</h3>
                <div className="title_svg">
                    <img src="/images/easy_title.svg" alt="title" />
                </div>
                <h3>DARES YOU TO...</h3>
            </div>
            <div className="content_main_after"></div>
        </div>
    );
}